<template>
  <div class="search">
    <div v-if="!searched">
      <div style="font-size: 18px;color: #000000;padding-top: 44px;margin-left: 16px;">请输入手机号</div>
      <div style="
      border-radius: 8px;
      margin: 25px 16px;
      background: #FFFFFF;">
        <div style="border: 2px solid #fff;
        border-radius: 6px;">
          <div style="height: 40px;
          margin: 16px 24px;
          display: flex;
          align-items: center;
          border-bottom: #E6EBF0 1px solid">
            <img style="width: 16px;height: 16px;" src="@/assets/images/phone.png">
            <input style="border: #FFFFFF 1px solid;
                font-size: 16px;
                flex: 1;
                margin-left: 12px;"
                   v-model="phoneNo"
                   placeholder="请输入手机号"/>
          </div>

        </div>
      </div>
      <div style="margin: 25px 16px;">
        <div style="margin-top:36px;width: 100%;height: 43px;background: #3A8AFF;border-radius: 4px;font-size: 16px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
             @click="driveIn">
          查询
        </div>
      </div>
    </div>
    <div v-else>
      <div style="font-size: 18px;color: #000000;padding-top: 44px;margin-left: 16px;font-weight: 600;">{{ parkinglotInfo.parkingLotName }}</div>
      <div style="font-size: 14px;color: #000000;padding-top: 12px;margin-left: 16px;">累计欠费订单：<span style="color: #3A8AFF;">{{ parkinglotInfo.orderCount }}</span></div>
      <div style="
      margin: 25px 16px;">
        <div v-for="(item, index) in orderList" :key="index"
             :style="{marginTop: index==0?'0px':'15px',}"
             style="background: #FFFFFF; border-radius: 6px;padding: 11px 12px;display: flex;align-items: center;">
          <div style="width: 66px;display: flex;align-items: center;justify-content: center;">
            <input type="checkbox" aria-hidden="false"
                   @change="inputChanged()"
                   style="width: 17.5px;height: 17.5px;" v-model="item.checked">
          </div>
          <div style="flex: 1;">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="color: #393939;font-size: 14px;">{{item.plateNumber}}</div>
              <div v-if="item.orderFlag=='0'" style="width: 52px;height: 18px;background: rgba(6, 189, 156, 0.08);color: #06BD9C;font-size: 10px;display: flex;align-items: center;justify-content:center;">当前订单</div>
            </div>
            <div style="width: 100%;height: 1px;background: #E6EBF0;margin: 12px auto;"></div>
            <div style="color: #000000;font-size: 12px;">停车时长：{{ item.parkingTime }}</div>
            <div style="color: #000000;font-size: 12px;margin-top: 12px;">驶入时间：{{ item.driveInTime }}</div>
            <div style="color: #000000;font-size: 12px;margin-top: 12px;">驶出时间：{{ item.driveOutTime }}</div>
            <div style="color: #777777;font-size: 12px;margin-top: 24px;">金额 <span style="color: #FE7134;">￥<span style="font-size: 16px;">{{ item.payableAmount }}</span> </span></div>
          </div>
        </div>
      </div>
      <div style="position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 54px;
				background: #fff;
				border-top: 1rpx solid #E6E6E6;
				display: flex;
				align-items: center;
				justify-content: space-between;">
        <input type="checkbox" aria-hidden="false"
               v-model="allChecked"
               @change="allChanged()"
               style="width: 17.5px;height: 17.5px;margin-left: 30px;">
        <div style="font-size: 12px;flex:1;">
          <div>全选</div>
          <div style="margin-top: 4px;">已选 {{orderList.filter(order => order.checked).length}}｜总金额：¥ {{ totalAmount.toFixed(2) }}</div>
        </div>
        <div style="width: 90px;height: 38px;background: #3A8AFF;
        border-radius: 4px;font-size: 14px;display: flex;align-items: center;
        justify-content: center;color: #FFFFFF;margin-right: 30px;"
             @click="toPay">
          去支付
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  noPlateParkingDriveOutList,
  // noPlateNumDriveOut,
} from "@/api/system";
// eslint-disable-next-line no-unused-vars
// import wx from "_weixin-jsapi@1.1.0@weixin-jsapi";
// import wx from "weixin-jsapi";


export default {
  data() {
    return {
      parkinglotId: '',
      parkingGateId: '',
      activeIndex: 0,
      plateColor:'02',
      phoneNo: '',
      parkinglotInfo: {},
      orderList: [],
      searched: false,
      allChecked: true,
      totalAmount: 0,
    }
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    this.parkinglotId = this.$route.query.parkinglotId;
    this.parkingGateId = this.$route.query.parkingGateId;
  },
  methods: {
    getTotalAmount(){
      this.totalAmount = 0
      this.orderList.forEach((order) => {
        if(order.checked){
          this.totalAmount+=Number(order.payableAmount)
        }
      })
    },
    allChanged(){
      this.orderList.forEach(order => order.checked = this.allChecked)
      this.getTotalAmount()
    },
    inputChanged(){
      this.allChecked = this.orderList.filter(order => order.checked===false).length<=0
      this.getTotalAmount()
    },
    isValidPhone() {
      // 中国大陆手机号码正则表达式
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(this.phoneNo);
    },
    driveIn(){
      if(!this.isValidPhone()){
        this.$message.warning('手机号有误，请重新输入')
        return
      }
      noPlateParkingDriveOutList({
        phone: this.phoneNo,
        parkingLotId: this.parkinglotId,
        parkingGateId: this.parkingGateId,
      }).then(res =>{
        if (res && res.code === 30) {
          this.parkinglotInfo = res.returnObject
          this.orderList = res.returnObject.orderList
          this.orderList.forEach((order) => order.checked = true)
          this.getTotalAmount()
          this.searched = true
        }
      })
    },
    toPay(){
      if(this.orderList.filter(order => order.checked).length===0){
        this.$message.warning('请先选择一个订单')
        return
      }
      var orderIds = this.orderList.filter(order => order.checked).map(order => order.orderId).join(',')
      this.$router.push("/payment?parkingOrderIntoId="+orderIds+
          "&source="+this.parkinglotInfo.source+
          '&amount='+this.totalAmount+
          '&parkingLotName='+this.parkinglotInfo.parkingLotName+
          '&wxAppId='+this.parkinglotInfo.wxAppId+
          '&zfbAppId='+this.parkinglotInfo.zfbAppId

      );
    },
  }
}
</script>


<style scoped>
.search{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F7F8FA;
  background-image: url("../../assets/images/search_bg.png");
  background-repeat: repeat-x;
  background-size: 100vw;
}

input:focus {
  border-bottom: 3px solid #fff;
  transition: all 0.5s;
}
</style>
